import styled, { css } from 'styled-components';

const Container = styled.div`
  padding: 2rem;
  
  ${({ align }) => align === 'left' && css`
    margin-left: 9%;
    margin-right: auto; /* Center the container */
    width: 50%; /* Minimum width to ensure content visibility */
    max-width: 60rem;


  `}
  ${({ align }) => align === 'right' && css`
    margin-left: auto; 
    margin-right: 5%; 
    align-content: right;
    width: 50%;
    max-width: 60rem;

  `}

  ${({ align }) => align === 'center' && css`
    display:block;
    margin-left: auto;
    margin-right: auto; /* Center the container */
    width: 80%; /* Minimum width to ensure content visibility */
    max-width: 60rem;

  `}
  ${({ align }) => align === 'full' && css`
    display:block;
    margin-left: auto;
    margin-right: auto; /* Center the container */
    width: 70%; /* Minimum width to ensure content visibility */
    padding: 0;

    overflow: hidden; /* Hide both horizontal and vertical overflow */
    position: relative; /* Add relative positioning */
  `}
  
  @media (max-width: 768px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%; /* Full width on small screens */
  }
`;

const Content = ({ children, align }) => {
  return <Container align={align}>{children}</Container>;
};

export default Content;
