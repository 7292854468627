import React from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import { MobileOnly } from '../components/MobileOnly';
import "../styles.css";
import Spacer from '../components/Spacer';

const PageContainer = styled.div`
  @media (max-width: 768px) {
    overflow-x: hidden; // Hide horizontal overflow only on mobile devices
  }
`;
const MobileHome = () => {
  return (
    
    <PageContainer>
      <MobileOnly>
        <Spacer />
        {/* <ButtonContainer> */}
          <Button type="page" infoContent="/projects/modelling" align="center" message="3d Modelling" />
          <Button type="page" infoContent="/projects/paintings" align="center" message="Paintings" />
          <Button type="page" infoContent="/projects/graphics" align="center" message="Graphics" />
          <Button type="page" infoContent="/projects/oneoffs" align="center" message="One Offs" />
          <Button type="page" infoContent="/about" align="center" message="About" />
        {/* </ButtonContainer> */}
      </MobileOnly>
    </PageContainer>
    
  );
};

export default MobileHome;
