import React from 'react';
import styled from 'styled-components';
import Artworks from '../components/Artworks';
import Layout from '../components/Layout';
import Content from '../components/Content';
import BackButton from '../components/Button';
import {MobileOnly} from '../components/MobileOnly';
import Footer from '../components/Footer';

import { Header, HeaderContainer, SubHeading } from '../components/TextComponents';
import Background from '../components/Background';
import Button from '../components/Button';


const Graphics = () => {
  return (
    <div>
      <BackButton type='back' align='left'/>
      <Content align="full">
        {/* Wrap header and subheading in HeaderContainer */}
        

        <Artworks category="graphics"/>
      </Content>
      <Button type='info' align='right' infoContent={
        <HeaderContainer>
        <Header align="right">Graphics</Header>
        <SubHeading align="right">
         Various digital art created in photoshop/adobe illsutrator 
          <br/>
          <i>2020–now</i>
        </SubHeading>
      </HeaderContainer>
      }/>

      <MobileOnly>
        <Footer />
      </MobileOnly>
    </div>
  );
};

export default Graphics;
