import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const ArtworkItem = styled.div`
  margin: 0.5rem;
  width: ${props => `calc(${props.width}% - 20px)`};
  flex-grow: 1;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const Video = styled.video`
  width: 100%;
  height: auto;
`;

const ArtworkTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  font-family: jost, sans-serif;
  font-weight: 500;
`;

const ArtworkYear = styled.p`
  margin: 0;
  font-size: 1rem;
  font-family: "eb-garamond", serif;
  font-style: italic;
`;

const ArtworkDescription = styled.p`
  margin: 0;
  font-size: 1rem;
  font-family: "eb-garamond", serif;
`;

const Gallery = ({ items, oneWork = false }) => {
  const [artworks, setArtworks] = useState([]);
  const videoRef = useRef(null);
  const itemsPerRow = 3;

  useEffect(() => {
    const fetchArtworkData = async () => {
      try {
        const response = await fetch('/artworks.json');
        if (!response.ok) {
          throw new Error('Failed to fetch artwork data');
        }
        const artworkData = await response.json();
        const fetchedArtworks = items.flatMap(filename => {
          const artwork = artworkData.find(art => art.source.includes(filename));
          return artwork ? { ...artwork, source: filename } : null;
        });
        setArtworks(fetchedArtworks);
      } catch (error) {
        console.error('Error fetching artwork data:', error);
      }
    };

    fetchArtworkData();
  }, [items]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 5;
    }
  }, [artworks]);

  const itemWidth = 100 / itemsPerRow;

  return (
    <>
      <GalleryContainer>
        {artworks.map((artwork, index) => (
          <ArtworkItem key={artwork.source} width={itemWidth}>
            {artwork.type === 'image' ? (
              <img src={`/images/${artwork.source}`} alt={artwork.title} style={{ width: '100%' }} />
            ) : artwork.type === 'video' ? (
              <Video ref={videoRef} controls autoPlay muted playsInline loop>
                <source src={`/videos/${artwork.source}`} type="video/mp4" />
                Your browser does not support the video tag.
              </Video>
            ) : null}
            {(!oneWork || index % itemsPerRow === 0) && <ArtworkTitle>{artwork.title}</ArtworkTitle>}
            {(!oneWork || index % itemsPerRow === 0) && <ArtworkDescription>{artwork.description}</ArtworkDescription>}
            {(!oneWork || index % itemsPerRow === 0) && <ArtworkYear>{artwork.year}</ArtworkYear>}

          </ArtworkItem>
        ))}
      </GalleryContainer>
    </>
  );
};

export default Gallery;
