// Modelling.js
import React from 'react';
import Artworks from '../components/Artworks';
import { MobileOnly } from '../components/MobileOnly';
import Content from '../components/Content';
import { Header, SubHeading, HeaderContainer} from '../components/TextComponents';
import Button from '../components/Button';
import Footer from '../components/Footer';


const Modelling = () => {
  return (
    <div>
      <Button type='back' align="left" />
        <Content align="full">
          {/* Wrap header and subheading in HeaderContainer */}
        <Artworks category="3d modelling" maxColumns={2} />
      </Content>
      <Button type='info' align='right' infoContent={
          <HeaderContainer>
            <Header align="right">3d Models</Header>
            <SubHeading align="right">
              A collection of 3d modelling experiments created in Blender
              <br/>
              <i>2021–now</i>
            </SubHeading>
          </HeaderContainer>}
        />
      <MobileOnly>
        <Footer />
      </MobileOnly>
    </div>
  );
};

export default Modelling;
