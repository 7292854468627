import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Content from '../components/Content'; // Import the Content component

const FilterButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

const FilterButton = styled(Link)`
  position: relative;
  height: 100vh;
  background-color: rgb(var(--primary-color));
  border: none;
  cursor: pointer;
  width: 15%;
  flex-grow: 1;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-size: contain ;
  background-position: center;
  overflow: hidden;
  transition: width 0.3s ease-out, height 0.3s ease;
  z-index: 1;
  margin-left: 3%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    /* background-color: rgb(var(--primary-color)); */
    color: rgb(var(--background-color));
    /* background-repeat: no-repeat; */
    width: 50%;
  }

  @media (max-width: 768px) {
    display: none;

  }

  &.active {
    text-decoration: underline;
  }
`;

const FilterButtonText = styled.span`
  position: absolute;
  bottom: 17.5vh;
  left: 90%;
  font-size: 3.5rem;
  line-height: 5rem;
  white-space: nowrap;

  transform: translateX(-50%) rotate(-90deg);
  color: rgb(var(--background-color));
  text-decoration: none;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 2;


`;

// const TextBackground = styled.div`
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   transition: width 0.3s ease, background-color 0.3s ease;
//   opacity: 1;
//   z-index: 0;
//   ${FilterButton}:hover & {
//     background-color: rgb(var(--primary-color));
//     width: 15%;
//   }

//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

const Projects = () => {
  return (
    <div>
        {/* <DesktopOnly> */}
        <Content align="full"> {/* Apply the Content component with center alignment */}
          <FilterButtonsContainer>
            <FilterButton to="/projects/modelling" bgImage="/images/TitB1.png">
              {/* <TextBackground /> */}
              <FilterButtonText>3D Models</FilterButtonText>
            </FilterButton>
            <FilterButton to="/projects/paintings" bgImage="/images/ABaoaS.jpg">
              {/* <TextBackground /> */}
              <FilterButtonText>Paintings</FilterButtonText>
            </FilterButton>
            <FilterButton to="/projects/graphics" bgImage="/images/LTT1.png">
              {/* <TextBackground /> */}
              <FilterButtonText>Graphics</FilterButtonText>
            </FilterButton>
            
            <FilterButton to="/projects/oneoffs" bgImage="/images/LMoPB1.png">
              {/* <TextBackground /> */}
              <FilterButtonText>One Offs</FilterButtonText>
            </FilterButton>
          </FilterButtonsContainer>
        </Content>
      {/* </DesktopOnly> */}
      
    </div>
  );
};

export default Projects;
