import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { DesktopOnly } from './MobileOnly';

// Styled components definitions...

const OuterContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
`;

const Container = styled.div`
  overflow: hidden;
  overflow-y: ${({ selectedArtwork }) => (selectedArtwork ? 'hidden' : 'auto')};
  position: relative; /* Add relative positioning */
  @media (max-width: 768px) {
    margin-top: 15rem;
    align-items: center; /* Center items vertically on mobile */
    flex-direction: column;
  }
`;


const ArtworkCard = styled.div`
  position: relative;
  cursor: ${({ isMobile }) => (isMobile ? 'auto' : 'pointer')};
  overflow: hidden;
  width: 15%;
  object-fit: cover;  
  flex-grow: 1;
  color: rgb(var(--background-color));
  background-color: transparent;
  background-size: cover;
  background-position: center;
  transition: width 0.3s ease;
  z-index: 1;
  text-decoration: none;
  display: flex;
  align-items: center;
  top: 50%;
  height: 100vh;
  max-width: 30%;
  
  @media (max-width: 768px) {
    width: auto;//;
    height:auto;
    margin-top: 2rem;
    /* height: ${({ portrait }) => (portrait ? 'auto' : '700px')}; */
    max-width: 90vw;
    max-height: 80vh;
    pointer-events: auto;
  }

  &:hover {
    ${({ isMobile }) =>
      !isMobile &&
      css`
        width: ${({ portrait }) => (portrait ? '200px' : '500px')};
        align-self: center;
        color: rgb(var(--background-color));
        background-color: rgb(var(--primary-color));

      `}
  }

  .active & {
    text-decoration: underline;
  }

  /* Additional styling for images */
  img video {
    width: auto;
    height: auto;
    object-fit: cover;  
    max-width:100%;
    max-height: 30%;

    @media (max-width: 768px) {
      width: ${({ aspectRatio }) => (aspectRatio <= 1 ? 'auto' : '100%')};
      height: ${({ aspectRatio }) => (aspectRatio <= 1 ? '100%' : 'auto')};
    }
  }
`;
const ArtworksContainer = styled.div`
  display: flex;
  justify-content: center;
  transition: width 0.3s ease;
  align-items: center; /* Center items vertically */
  gap: 0.2rem;
  @media (max-width: 768px) {
    align-items: center; /* Center items vertically */
    flex-direction: column;
  }
`;

const ArtworkImage = styled.img`
  max-height: 100vh;
  max-width: 100%;
  background-color: transparent;
  width: auto;
  height: auto;
  @media (max-width: 768px) {
    width: ${({ portrait }) => (portrait ? 'auto' : '100%')};
    height: ${({ portrait }) => (portrait ? '100%' : 'auto')};
    pointer-events: auto;
  }
`;

const ArtworkTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  font-family: jost, sans-serif;
  font-weight: 500;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 6vh;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${({ modalOpen }) => (modalOpen ? 1 : 0)};
  transition: opacity 0.3s ease;

  ${ArtworkCard}:hover & {
    opacity: 1;
  }

  @media (max-width: 768px) {
    /* position: relative; */
    font-size: 2rem;
    bottom: 12vh;

  }
`;

const ArtworkYear = styled.p`
  margin: 0;
  font-size: 1rem;
  font-family: "eb-garamond", serif;
  font-style: italic;
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${({ modalOpen }) => (modalOpen ? 1 : 0)};
  transition: opacity 0.3s ease;
  ${ArtworkCard}:hover & {
    opacity: 1;
  }

  @media (max-width: 768px) {
    /* position: relative; */
    font-size: 1.5rem;
    bottom: 6vh;

  }
`;

const ArtworkDescription = styled.p`
  margin: 0;
  font-size: 1rem;
  font-family: "eb-garamond", serif;
  position: absolute;
  bottom: 1.75rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${({ modalOpen }) => (modalOpen ? 1 : 0)};
  transition: opacity 0.3s ease;

  ${ArtworkCard}:hover & {
    opacity: 1;
  }

  @media (max-width: 768px) {
    /* position: relative; */
    font-size: 1.25rem;
    text-align: center;
    bottom: 10vh;

  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background-color: rgb(var(--background-color));
  /* border-radius: 5px; */
  max-width: 80%;
  height: 100vh;
  overflow: hidden;
  z-index: 100000;
  position: relative;


  @media (max-width: 768px) {
    /* max-width: ${({ portrait }) => (portrait ? 'auto' : '100%')}; */
    /* max-height: 100vh; */
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    display: block;
    /* margin-top: 10rem; */
  }
`;

const ModalImage = styled.img`
  width: ${({ portrait }) => (portrait ? '80vw' : 'auto')};
  height: ${({ portrait }) => (portrait ? 'auto' : '85vh')};
  align-items: left;
  
  @media (max-width: 768px) {
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: auto;
    height: auto;
    max-height:70vh;
    max-width:100vw;

    pointer-events: auto;
  }
`;


const ModalCloseButton = styled.button`
  font-family: 'jost', sans-serif;
  position: absolute;
  top: -2rem;
  right: 1rem;
  background-color: transparent;
  font-weight: 300;
  border: none;
  cursor: pointer;
  font-size: 5rem;
  color: rgb(var(--primary-color));
  z-index: 10;
  transition: color 0.3s ease;

  &:hover {
    color: rgb(var(--background-color));
  }
`;

const BackNextButton = styled.button`
  position: absolute;
  bottom: ${({ bottom }) => bottom}; /* Set the bottom position */
  ${({ side }) => side}: ${({ sideOffset }) => sideOffset}; /* Set the left or right position */
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  color: black;
  z-index: 10;
  transition: color 0.3s ease;

  &:hover {
    color: rgb(var(--background-color));
    background-color: rgb(var(--primary-color));
  }

  @media (max-width: 768px) {
    font-size: 3.5rem;
    pointer-events: auto;
  }
`;

const VideoPlayer = styled.video`
  max-height: 80%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: auto;
    max-height: 70vh;
    pointer-events: auto;
  }
`;

// Define other styled components...

const Artworks = ({ category, maxColumns }) => {
  const [artworks, setArtworks] = useState([]);
  const [originalArtworks, setOriginalArtworks] = useState([]);
  const [selectedArtwork, setSelectedArtwork] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    fetch('/artworks.json')
      .then(response => response.json())
      .then(data => {
        setOriginalArtworks(data);
        filterArtworks(data, category); // Filter artworks based on the category
      })
      .catch(error => console.error('Error fetching artworks:', error));
  }, [category]); // Trigger the effect when the category changes

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  const filterArtworks = (data, category) => {
    if (category === 'All') {
      setArtworks(data.slice().sort((a, b) => a.title.localeCompare(b.title)));
    } else if (category) {
      setArtworks(data.filter(artwork => artwork.category === category));
    }
  };

  const openModal = artwork => {
    setSelectedArtwork(artwork);
    setSelectedImageIndex(prevIndex => ({
      ...prevIndex,
      [artwork.id]: 0 // Set the initial index to 0
    }));
    setModalOpen(true); // Set modalOpen state to true when modal is opened
  };

  const closeModal = () => {
    setSelectedArtwork(null);
    document.body.style.overflow = 'auto';
    setModalOpen(false); // Set modalOpen state to false when modal is closed
  };

  const nextImage = () => {
    setSelectedImageIndex(prevIndex => ({
      ...prevIndex,
      [selectedArtwork.id]: (prevIndex[selectedArtwork.id] + 1) % selectedArtwork.source.length
    }));
  };

  const prevImage = () => {
    setSelectedImageIndex(prevIndex => ({
      ...prevIndex,
      [selectedArtwork.id]:
        prevIndex[selectedArtwork.id] === 0 ? selectedArtwork.source.length - 1 : prevIndex[selectedArtwork.id] - 1
    }));
  };

  const stopPropagation = e => {
    e.stopPropagation();
  };

  return (
    <Container selectedArtwork={selectedArtwork}>
      <ArtworksContainer maxColumns={maxColumns}>
        {artworks.map((artwork, index) => (
          <ArtworkCard
            key={artwork.title}
            onClick={() => openModal(artwork)}
            isSelected={selectedArtwork && selectedArtwork.id === artwork.id}
            isMobile={isMobile} // Pass isMobile prop
            portrait={artwork.aspectRatio <= 1}
          >
            {artwork.type === 'image' && (
              <ArtworkImage
                src={`/images/${artwork.source[0]}`}
                alt={artwork.title}
                portrait={artwork.aspectRatio <= 1}
              />
            )}
            {artwork.type === 'video' && (
              <VideoPlayer controls autoPlay loop muted>
                <source src={`/videos/${artwork.source}`} type="video/mp4" />
                Your browser does not support the video tag.
              </VideoPlayer>
            )}
            <DesktopOnly>            
              <ArtworkTitle>{artwork.title}</ArtworkTitle>
              <ArtworkDescription>{artwork.description}</ArtworkDescription>
              <ArtworkYear>{artwork.year}</ArtworkYear>
            </DesktopOnly>
          </ArtworkCard>
        ))}
      </ArtworksContainer>

      {selectedArtwork && (
        <ModalBackground onClick={closeModal}>
          <ModalContent onClick={stopPropagation}>
            <ModalCloseButton onClick={closeModal}>×</ModalCloseButton>
            {selectedArtwork.source.length > 1 && (
              <>
                <BackNextButton onClick={prevImage} bottom="calc(8% - 1rem)" side="left" sideOffset="1rem">
                  {'<'}
                </BackNextButton>
                <BackNextButton onClick={nextImage} bottom="calc(8% - 1rem)" side="right" sideOffset="1rem">
                  {'>'}
                </BackNextButton>
              </>
            )}
            <ModalImage
              src={`/images/${selectedArtwork.source[selectedImageIndex[selectedArtwork.id]] || 0}`}
              portrait={selectedArtwork.aspectRatio <= 1}
              onClick={nextImage}
            />
            {selectedArtwork.type === 'video' && (
                <VideoPlayer controls autoPlay loop muted>
                    {Array.isArray(selectedArtwork.source) ? (
                      selectedArtwork.source.map((src, index) => (
                        <source key={index} src={`/videos/${src}`} type="video/mp4" />
                      ))
                    ) : (
                      <source src={`/videos/${selectedArtwork.source}`} type="video/mp4" />
                    )}
                    Your browser does not support the video tag.
                </VideoPlayer>

            )}
            <ArtworkTitle modalOpen={modalOpen}>{selectedArtwork.title}</ArtworkTitle>
            <ArtworkDescription modalOpen={modalOpen}>{selectedArtwork.description}</ArtworkDescription>
            <ArtworkYear modalOpen={modalOpen}>{selectedArtwork.year}</ArtworkYear>
          </ModalContent>
        </ModalBackground>
      )}
    </Container>
  );
};

export default Artworks;
