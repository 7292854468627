import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LogoContainer = styled.div`
  position: fixed;
  display: block;
  margin-left: auto;
  margin-right: auto;
  left: -.75rem;
  top: -.75rem;
  z-index: 999;
  pointer-events: none;
  width: ${({ logoWidth }) => logoWidth};
  transition: width 0.3s ease;

  @media (max-width: 768px) {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    margin: 0 auto;
  }
`;

const LogoImage = styled.img`
  height: auto;
  width: 100%;
`;

// Functional Component for Logo
const Logo = ({ manualToggle }) => {
  const [logoWidth, setLogoWidth] = useState('14rem');

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const newLogoWidth = (scrollTop === 0 && !manualToggle) ? '14rem' : '10rem';
      setLogoWidth(newLogoWidth);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [manualToggle]);

  return (
    <div>
      <LogoContainer logoWidth={logoWidth}>
        {/* <RotatingName 
          title="Jackson Dunnigan Jackson Dunnigan Jackson Dunnigan Jackson Dunnigan Jackson Dunnigan" 
          desc="" 
          alt={true} 
          additionalProp="This is an additional prop"
          logoWidth={logoWidth}
        /> */}
        <LogoImage src="/images/animatedCharacter.gif" alt="loading..." />
      </LogoContainer>
    </div>
  );
}

Logo.propTypes = {
  manualToggle: PropTypes.bool.isRequired,
};

export default Logo;
