import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import "../styles.css";

const NameContainer = styled.a`
    position: fixed;
    top: -1rem;
    right: -1rem;
    padding: 20px;
    font-size: ${({ scrolled }) => (scrolled ? '.8rem' : '1.25rem')};
    transition: font-size 0.3s ease;
    z-index: 9999;
    font-family: 'Jost', sans-serif;
    color: rgb(var(--primary-color));
    font-weight: 500;
    transition: font-size 0.3s ease;

    @media (max-width: 767px) {
      display: none;
    }
`;

const Name = () => {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  const handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    setScrolled(isScrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <NameContainer scrolled={scrolled} navigate={navigate}>Jackson Dunnigan</NameContainer>
  );
};

export default Name;
