import React from 'react';
import styled, { css } from 'styled-components';

export const DesktopOnly = styled.div` 
  @media (max-width: 768px) { 
    display: none;
  }
`;
export const MobileOnly = styled.div` 
  display: none;
  @media (max-width: 768px) { 
    display:block;
  }
`;
