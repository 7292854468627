import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import '../styles.css';

const StyledButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: clamp(5vh, 10vh, 20vh); /* Adjust height based on viewport size */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10; 
  transition: color 0.3s ease;
  font-family: 'jost', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: clamp(3rem, 5rem, 8rem); /* Adjust font size based on viewport size */
  color: rgb(var(--primary-color)); /* Initially set to primary color */

  &:hover {
    background-color: rgb(var(--primary-color));
    color: rgb(var(--background-color));
  }

  @media (max-width: 768px) {
    left: 1%;
    top: 5%;
    font-size: 4rem; /* Adjust font size based on viewport size */
    color: white; /* Change color to white in mobile view */
  }

  ${({ align }) => align === 'left' && css`
    left: clamp(5%, 15%, 10%);

    @media (max-width: 768px) {
      display: block;
      position: fixed;
      font-size:4rem;
      text-align: center;
      margin-top: 1rem;
      background-color: transparent;
      color: rgb(var(--primary-color));
      width:12.5%;

      height:auto;

      &:hover{
        background-color: transparent;
        color: rgba(var(--background-color));
        -webkit-text-stroke: 1px rgb(var(--primary-color));
      }
    }
  `}

  ${({ align }) => align === 'center' && css`
    left: 50%;
    width: 25rem;
    height: 4rem;
    position: relative;
    margin-top: 6rem;
    background-color: rgb(var(--primary-color));
    color: rgb(var(--background-color));
    letter-spacing: 0.1rem;
    font-size: 2rem; /* Adjust font size based on viewport size */
    transform: translateX(-50%) translateY(-50%);
    text-decoration: none;
    
    @media (max-width: 768px) {
      display:block;
      font-size:2.5rem;
      text-align: center;
      margin-top: 1rem;

      width:80%;
      height:auto;
      margin-left: 50%;
    }

    &:hover {
      outline: 1px solid rgb(var(--primary-color));
      background-color: transparent;
      color: rgb(var(--primary-color));
    }
    `}

  ${({ align }) => align === 'right' && css`
    right: clamp(5%, 15%, 10%);

    @media (max-width: 768px) {
      display: block;
      position: fixed;
      font-size:4rem;
      text-align: center;
      margin-top: 1rem;
      background-color: transparent;
      color: rgb(var(--primary-color));
      width:12.5%;
      left:calc(100vw - 4rem);
      height:auto;
      &:hover{
        background-color: transparent;
        color: rgba(var(--background-color));
        -webkit-text-stroke: 1px rgb(var(--primary-color));
      }
    }
  `}
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  width: 50%;
  background-color: white;
  padding: 20px;
  @media (max-width: 768px) {
    width: 80%;

  }
`;

const ModalClose = styled.button`
  position: relative;
  top: -2rem;
  left: calc(100% - 3rem);

  transition: color 0.3s ease;
  font-family: 'jost', sans-serif;
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 400;
  border: none;
  cursor: pointer;
  font-size: 5rem;
  color: rgb(var(--primary-color));
  z-index: 10;
  transition: color 0.3s ease;

  &:hover {
    color: rgba(var(--background-color));
    -webkit-text-stroke: 1px rgb(var(--primary-color));
    /* background-color: rgba(var(--primary-color)); */

  }
`;

const Button = ({ type, infoContent, align, message}) => {
  const goBack = () => {
    window.history.back();
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {type === 'back' && <StyledButton align={align} onClick={goBack}>&lt;</StyledButton>}
      {type === 'info' && (
        <>
          <StyledButton align={align} onClick={toggleModal}>?</StyledButton>
          {showModal && (
            <Modal>
              <ModalContent>
                <ModalClose onClick={toggleModal}>&times;</ModalClose>
                {infoContent}
              </ModalContent>
            </Modal>
          )}
        </>
      )}
      {type === 'page' && (
        <StyledButton as={Link} align={align} to={infoContent}>{message}</StyledButton>
      )}
    </>
  );
};

export default Button;
