import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Spacer from '../components/Spacer';
import Content from '../components/Content';
import Gallery from '../components/Gallery';
import Button from '../components/Button';
import Footer from '../components/Footer';
import { Header, HeaderContainer, SubHeading } from '../components/TextComponents';
import "../styles.css";

// Styled component for the Featured page container
const PageContainer = styled.div`
  @media (max-width: 768px) {
    overflow-x: hidden; // Hide horizontal overflow only on mobile devices
  }
`;

// const Button = styled(Link)`
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 1rem;
//   width: 25%;
//   background-color: rgb(var(--primary-color));
//   color: rgb(var(--background-color));
//   padding: 0.5rem 1rem;
//   text-decoration: none;
//   text-align: center;
//   transition: color 0.3s ease;
//   font-family: 'jost', sans-serif;
//   text-transform: uppercase;
//   font-size: 1.5rem;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: rgb(var(--background-color));
//     color: rgb(var(--primary-color));
//   }
// `;

const Featured = () => {
  return (
    <PageContainer>
      <Spacer />
      <Content align="full">
        {/* Pass file names as an array to the Gallery component */}
        <Gallery items={["LMoP.mp4"]} />
        <Gallery items={["EC.mp4"]} />
        <Gallery items={["FoMoaH.jpg"]} />
        {/* Button to redirect to Projects page */}
        <Button type="page" infoContent="/projects" align="center" message="View Projects" />

      </Content>
      <Footer />
    </PageContainer>
  );
};

export default Featured;
