import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; 
import MobileHome from './pages/MobileHome';
import Featured from './pages/Featured';
import Projects from './pages/Projects';
import OneOffs from './pages/OneOffs';
import Graphics from './pages/Graphics';
import Modelling from './pages/Modelling'; 
import Paintings from './pages/Paintings'; 
import About from './pages/About';
import Logo from './components/Logo';
import Name from './components/Name';
import Background from './components/Background';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      const isMobileUserAgent = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const isMobileMediaQuery = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(isMobileUserAgent || isMobileMediaQuery);
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <Router>
      <div className="app">
        <Background />
        <Navbar /> 
        <Logo />
        <Name />
        <Routes>
          <Route exact path="/" element={isMobile ? <MobileHome /> : <Featured />} />
          <Route path="/featured" element={<Featured />} />
          <Route path="/projects" element={isMobile ? <MobileHome /> : <Projects />} /> {/* Redirect to MobileHome if on mobile */}
          <Route path="/about" element={<About />} />
          <Route path="/projects/modelling" element={<Modelling />} />
          <Route path="/projects/paintings" element={<Paintings />} />
          <Route path="/projects/oneoffs" element={<OneOffs />} />
          <Route path="/projects/graphics" element={<Graphics />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
