import React from 'react';
import Artworks from '../components/Artworks';
import Content from '../components/Content';
import Button from '../components/Button';
import { Header, HeaderContainer, SubHeading } from '../components/TextComponents';
import {MobileOnly} from '../components/MobileOnly';
import Footer from '../components/Footer';

const Paintings = () => {
  return (
    <div>
      <Button type='back' align = 'left'/>
      <Content align="full">
        <Artworks category="paintings" maxColumns={3} />
      </Content>
      <Button type='info' align = 'right' infoContent={
        <HeaderContainer>
          <Header align="right">The Ongoing Painting Project</Header>
          <SubHeading align="right">
            A collection of works exploring abstract form
            <br />
            <i>2021–now</i>
          </SubHeading>
        </HeaderContainer>} 
      />
      <MobileOnly>
        <Footer/>
      </MobileOnly>
    </div>
  );
};

export default Paintings;
