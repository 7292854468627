import React from 'react';
import styled from 'styled-components';
import Artworks from '../components/Artworks';
import Footer from '../components/Footer';
import { MobileOnly } from '../components/MobileOnly';
import Content from '../components/Content';
import Button from '../components/Button';
import Spacer from '../components/Spacer';
import { Header, HeaderContainer, SubHeading } from '../components/TextComponents';
import Background from '../components/Background';


const OneOffs = () => {
  return (
    <div>
      <Button type='back' align='left'/>
      <Content align="full">
        {/* Wrap header and subheading in HeaderContainer */}
        

        <Artworks category="oneoff"/>
      </Content>
      <Button type='info' align='right' infoContent={
        <HeaderContainer>
        <Header align="right">One Offs and Oddities</Header>
        <SubHeading align="right">
         Various projects that don't fit into other categories
          <br />
          <i>2020–now</i>
        </SubHeading>
      </HeaderContainer>
      }/>
      <MobileOnly>
        <Footer />
      </MobileOnly>
    </div>
  );
};

export default OneOffs;
