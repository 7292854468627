import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {DesktopOnly} from './MobileOnly';
import "../styles.css";

const SidebarStyle = styled.nav`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: ${({ navWidth }) => navWidth};
  transition: width 0.3s ease;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 14rem;
  }
`;

const NavbarBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 25%;
  background-color: rgb(var(--primary-color));
`;

const Hotbar = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin:0;
  margin-left: ${({ marginLeft }) => marginLeft};
  transition: margin-left 0.3s ease;
  @media (max-width: 768px) {
    margin-left: .05rem;
  }
`;

const HotbarItem = styled.li`
  color: white;
  font-weight: 450;
  font-size: ${({ fontSize }) => fontSize};
  margin-top: ${({ marginTop }) => marginTop};
  writing-mode: vertical-rl; /* Rotate text sideways */
  transform: rotate(180deg); /* Rotate text in opposite direction */
  transition: font-size 0.3s ease;
  width: 3rem; /* Fixed width to prevent movement */
  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
`;

const HotbarLink = styled(NavLink)`
  display: block;
  color: inherit;
  text-decoration: none;
  transition: letter-spacing 0.3s ease; /* Add transition for letter-spacing */

  &:hover {
    letter-spacing: .1rem; /* Adjust letter-spacing on hover */
  }

  &.active {
    letter-spacing: .1rem; /* Adjust letter-spacing for active link */
    text-decoration: underline; /* Add underline to active link */
  }
`;

const TextContainer = styled.div`
  position: absolute;
  left: -.5rem;
  padding: .35rem;
  bottom: 0rem; /* Aligning text to the bottom */
  display: flex;
  flex-direction: column;
  z-index: 3; /* Ensure text is on top */
`;

const Navbar = ({ manualToggle }) => {
  const [navWidth, setNavWidth] = useState('20rem');
  const [fontSize, setFontSize] = useState('1.75rem');
  const [marginTop, setMarginTop] = useState('1rem');
  const [marginLeft, setMarginLeft] = useState('-.15rem');
   
  // Mobile only code
  // const [isOpen, setIsOpen] = useState(false);
  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };
  // const handleItemClick = (infoContent) => {
  //   console.log(`Navigating to ${infoContent}`);
  // };
    
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const newNavWidth = (scrollTop === 0 && !manualToggle) ? '20rem' : '15rem';
      setNavWidth(newNavWidth);

      const newFontSize = (scrollTop === 0 && !manualToggle) ? '1.75rem' : '1.5rem';
      setFontSize(newFontSize);

      const newMarginTop = (scrollTop === 0 && !manualToggle) ? '1.5rem' : '1rem';
      setMarginTop(newMarginTop);

      const newMarginLeft = (scrollTop === 0 && !manualToggle) ? '-.15rem' : '-.4rem';
      setMarginLeft(newMarginLeft);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, [manualToggle]);

  return (
    <>
      <SidebarStyle navWidth={navWidth}>
        <DesktopOnly>
        <NavbarBackground />
        <TextContainer>
          <Hotbar marginLeft={marginLeft}>
            <HotbarItem marginTop={marginTop} fontSize={fontSize}>
              <HotbarLink to="/featured">Featured</HotbarLink>
            </HotbarItem>
            <HotbarItem marginTop={marginTop} fontSize={fontSize}>
              <HotbarLink to="/projects">Projects</HotbarLink>
            </HotbarItem>
            <HotbarItem marginTop={marginTop} fontSize={fontSize}>
              <HotbarLink to="/about" className="alt-hover">About</HotbarLink>
            </HotbarItem>
          </Hotbar>
        </TextContainer>
        </DesktopOnly>
      </SidebarStyle>
    </>
  );
};

Navbar.propTypes = {
  manualToggle: PropTypes.bool.isRequired,
};

export default Navbar;
